export const boolean = [
    {value: 1, name: 'Si'},
    {value: 0, name: 'No'},
]

export const ocasType =[
    { value: 1, name: 'IRG'},
    {value: 9, name: 'RITE'},
    {value: 12, name: 'BT'},
    {value: 25, name: 'E.P.'},
    {value: 42, name: 'GNL'},
    {value: 52, name: 'ES'},
    {value: 80, name: 'LAT'},
    {value: 83, name: 'RAT'},
    {value: 96, name: 'EA'},
    {value: 113, name: 'IF'},
    {value: 135, name: 'A'},
]

export const ocasPeriodicity = [
    { value: 'Inicial', name: 'Inicial'},
    { value: '5 años', name: '5 años'},
    { value: '4 años', name: '4 años'},
    { value: '1 año', name: '1 año'},
    { value: '3 años', name: '3 años'},
    { value: '6 años', name: '6 años'},
    { value: '15 años', name: '15 años'},
    { value: '8 años', name: '8 años'},
    { value: '2 años', name: '2 años'},
    { value: '10 años', name: '10 años'},
]